import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

Vue.component('sticky-notes', App)

new Vue({
    el: '#sticky-notes'
})
