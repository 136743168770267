<template>
    <div>
        <div class="sticky__form">
            <textarea type="text" v-model="form.content" autocomplete="off" placeholder="What do you intend to do?"></textarea>
            <div>
                <span v-for="color in colors" :class="[{ sticky__color: true, active: color == form.color }, 'sticky__color_' + color]" @click="setFormColor(color, $event)"></span>
                <div style="float: right">
                    <input type="text" :value="search" @input="updateSearch" autocomplete="off" placeholder="Search..."/>
                    <button @click="add()">Post</button>
                </div>
            </div>
            <div style="clear:both"></div>
        </div>

        <div id="sticky__container" class="sticky__wrapper">
            <div class="sticky__error" v-show="quota_error">Limit reached for public user. Please <a href="index2.php?action=user/login">login</a> to continue...</div>
            <div class="sticky__tabs" style="display:none" v-show="groups.length">
                <ul>
                    <li v-for="(group, i) in groups" :data-group="group" :id="'groups_' + group"><a :href="'#sticky__cat_' + i">{{ group }}</a></li>
                    <li @click="newGroup()"><span class="icon-add sticky__btn__new"></span></li>
                </ul>

                <div v-for="(group, i) in groups" :id="'sticky__cat_' + i">
                    <div class="sticky__items" v-show="filteredItems[group].length">
                        <StickyNoteItem
                            v-for="(item, index) in filteredItems[group]"
                            :key="item.id"
                            :item="item"
                            :group="group"
                            :index="index"
                            :groups="groups"
                            :colors="colors"
                            :updateContent="updateContent"
                            :updateItemColor="updateItemColor"
                            :updateItemNote="updateItemNote"
                            :up="up"
                            :remove="remove"
                            :down="down"
                            :toggleConfig="toggleConfig"
                            :isConfig="item.id == configId"
                            :toggleEditing="toggleEditing"
                            :toggleEditingNote="toggleEditingNote"
                            :isEditing="item.id == editingId"
                            :isEditingNote="item.id == editingNoteId"
                            :moveTo="moveTo"
                        ></StickyNoteItem>
                    </div>
                    <div v-else class="sticky__empty">This group has no sticky note</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StickyNoteItem from '@/components/StickyNoteItem';
import debounce from 'lodash.debounce';

export default {
    props: {
        uuid: { type: String, required: true },
        channel: { type: String, required: true }
    },
    components: {
        StickyNoteItem
    },
    data: function () {
        return {
            colors: [1, 2, 3, 4],
            items: {},
            form: {
                content: '',
                color: 1,
                group: ''
            },
            search: '',
            quota_error: false,
            configId: '',
            editingId: '',
            editingNoteId: ''
        }
    },
    computed: {
        groups: function () {
            return Object.keys(this.filteredItems)
        },
        filteredItems: function () {
            if (this.search.length < 2) {
                return this.items
            }

            let filter = function (items, search) {
                return items.filter(item => {
                    return item.content.toLowerCase().includes(search.toLowerCase())
                })
            }

            let results = {}

            for (var group in this.items) {
                let matches = filter(this.items[group], this.search)

                if (matches.length) {
                    results[group] = matches
                }
            }

            return results
        }
    },
    watch: {
        filteredItems: {
            handler: function (newVal, oldVal) {
                let that = this

                this.$nextTick(function () {
                    that.onItemsChanged()
                })
            },
            deep: true
        }
    },
    created: function () {
        this.loadData()
    },
    mounted: function () {
        this.onMounted()
    },
    methods: {
        updateSearch: debounce(function (e) {
            this.search = e.target.value
        }, 300),
        loadData: function() {
            let that = this

            $.ajax({
                url: "index2.php?action=sticky-notes/getItems",
                type: "GET",
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        that.items = response.items
                    }
                }
            })
        },
        refresh: function() {
            this.loadData()
        },
        onItemsChanged: function() {
            let that = this

            $(function() {
                ensureTabs($(that.$el).find('.sticky__tabs'))

                that.ensureItemsSortable()
                that.ensureGroupsSortable()
            })
        },
        onMounted: function() {
            let that = this

            $(function() {
                $(that.$el).find('.sticky__form textarea').focus(function(){
                    $(this).addClass('focused')
                })

                $(document).click(function(event) {
                    if (!$(event.target).is('.sticky__form textarea') && !$(event.target).is('.sticky__form input')) {
                        $(that.$el).find('.sticky__form textarea').removeClass('focused')
                    }

                    if (!$(event.target).is('.sticky__item') && $(event.target).closest('.sticky__item').length == 0) {
                        that.configId = ''
                        that.editingId = ''
                    }
                })

                that.initWebSocket()
            })
        },
        initWebSocket: function () {
            let sock = new ReconnectingWebSocket('wss://cloudpad9.com:9999/echo/websocket', null, {debug: false, reconnectInterval: 1000})

            this.sock = sock

            let that = this

            sock.onopen = function () {
                 sock.send(JSON.stringify({ channel: that.getWebSocketChannel(), command: 'subscribe' }))
            }

            sock.onmessage = function (event) {
                let message = JSON.parse(event.data)

                if (message.uuid && message.uuid != that.uuid) {
                    that.refresh()
                }
            }
        },
        notifyWebSocket: function() {
            if (this.sock) {
                this.sock.send(JSON.stringify({ channel: this.getWebSocketChannel(), uuid: this.uuid }))
            }
        },
        getWebSocketChannel: function() {
            return this.channel
        },
        toggleConfig: function (item, event) {
            let ele = event.currentTarget

            if (!getSelectionText()) {
                if (this.editingId == item.id) {
                    return
                }

                this.editingId = ''

                this.configId = item.id
            }
        },
        toggleEditing: function (item, event) {
            this.editingId = item.id
        },
        toggleEditingNote: function (item, event) {
            this.editingNoteId = item.id
        },
        ensureItemsSortable: function () {
            var list = $(this.$el).find(".sticky__items")

            list.sortable({
                handle: ".sticky__handle",
                stop: function (event, ui) {
                    var order = list.sortable('serialize', { expression: /(.+)_(.+)/ });

                    $.ajax({
                        type: "post",
                        url: "index2.php?action=sticky-notes/update_ordering",
                        data: order,
                        success: function(response) {
                            if (response.success) {
                            }
                        }
                    });
                }
            })
        },
        ensureGroupsSortable: function () {
            if (MobileDetect.any()) {
                return
            }

            var list = $(this.$el).find(".sticky__tabs >ul")

            list.sortable({
                stop: function (event, ui) {
                    var order = list.sortable('serialize', { expression: /(.+)_(.+)/ });

                    $.ajax({
                        type: "post",
                        url: "index2.php?action=sticky-notes/update_groups_ordering",
                        data: order,
                        success: function(response) {
                            if (response.success) {
                            }
                        }
                    });
                }
            })
        },
        setFormColor: function (color, event) {
            this.form.color = color
        },
        updateItemColor: function (item, color, event) {
            let span = $(event.currentTarget)
            let that = this

            that.$set(item, 'color', color)

            $.ajax({
                url: "index2.php?action=sticky-notes/update_color&id=" + item.id + "&color=" + color,
                type: "GET",
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        that.$set(item, 'color', response.color)

                        that.notifyWebSocket()
                    }
                }
            });
        },
        updateItemNote: function (item, note, event) {
            let that = this

            $.ajax({
                url: "index2.php?action=sticky-notes/update_note&id=" + item.id + "&note=" + encodeURIComponent(note),
                type: "GET",
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        that.$set(item, 'note', response.note)

                        that.editingNoteId = ''

                        that.notifyWebSocket()
                    }
                }
            });
        },
        newGroup: function() {
            let that = this

            jPrompt('Enter title of a new group:', '', 'New group', function (value) {
                if (value) {
                    if (!that.items[value]) {
                        that.$set(that.items, value, [])
                    }
                }
            })
        },
        add: function() {
            let that = this
            let group = $(this.$el).find('.sticky__tabs li.ui-tabs-active').data('group')

            that.form.group = group

            $.ajax({
                url: "index2.php?action=sticky-notes/add",
                type: "POST",
                dataType: 'json',
                data: this.form,
                success: function(response) {
                    if (response.success) {
                        let item = response.item
                        let group = item.group

                        if (!that.items[group]) {
                            that.$set(that.items, group, [])
                        }

                        that.items[group].push(item)
                        that.form.content = ''

                        $(that.$el).find('.sticky__form textarea').removeClass('focused')

                        that.notifyWebSocket()
                    } else {
                        if (response.quota_error) {
                            that.quota_error = true
                        }
                    }
                }
            })
        },
        updateContent: function(item, editingContent, event) {
            let ele = event.currentTarget
            let that = this

            $.ajax({
                url: "index2.php?action=sticky-notes/update_content&id=" + item.id + "&content=" + encodeURIComponent(editingContent),
                type: "GET",
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        item.content = response.content

                        that.editingId = ''

                        that.notifyWebSocket()
                    }
                }
            })
        },
        up: function(item, event) {
            let ele = event.currentTarget
            let that = this

            that.$set(item, 'number', item.number ? item.number + 1 : 1)

            $.ajax({
                url: "index2.php?action=sticky-notes/up&id=" + item.id,
                type: "GET",
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        that.$set(item, 'number', response.number)

                        that.notifyWebSocket()
                    }
                }
            })
        },
        remove: function(group, index, event) {
            let ele = event.currentTarget
            let that = this
            let item = this.items[group][index]

            ensureMessage($(ele), function() {
                $.ajax({
                    url: "index2.php?action=sticky-notes/delete&id=" + item.id,
                    type: "GET",
                    dataType: 'json',
                    success: function(response) {
                        if (response.success) {
                            that.items[group].splice(index, 1);

                            that.notifyWebSocket()
                        }
                    }
                })
            })
        },
        moveTo: function(group, targetGroup, index, event) {
            let ele = event.currentTarget
            let that = this
            let item = this.items[group][index]

            $.ajax({
                url: "index2.php?action=sticky-notes/update_group&id=" + item.id + "&group=" + targetGroup,
                type: "POST",
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        that.items[group].splice(index, 1)

                        that.items[targetGroup].push(item)

                        that.notifyWebSocket()
                    }
                }
            })
        },
        down: function(item, event) {
            let ele = event.currentTarget
            let that = this

            that.$set(item, 'number', item.number > 0 ? item.number - 1 : 0)

            $.ajax({
                url: "index2.php?action=sticky-notes/down&id=" + item.id,
                type: "GET",
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        that.$set(item, 'number', response.number)

                        that.notifyWebSocket()
                    }
                }
            })
        }
    }
}
</script>

<style>
#tabs-sticky_notes {
    padding: 0;
    background: #f3f3f2;
}
.sticky__wrapper {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    clear: both;
    position: relative;
    padding-bottom: 35px;
}
.ui-tabs.sticky__tabs .ui-tabs-panel {
    padding: 0;
}
.ui-tabs.sticky__tabs .ui-tabs-nav {
    background: #fff;
    margin: 0 7px;
}
.ui-tabs.sticky__tabs .ui-tabs-nav .ui-tabs-anchor {
    text-transform: uppercase;
}
.ui-tabs.sticky__tabs .ui-tabs-nav .ui-tabs-active .ui-tabs-anchor {
    color: #37A000;
    font-weight: bold;
}
.sticky__items {
    padding: 0 10px;
}
.sticky__item {
    position: relative;
    background: #fff6a7;
    margin: 10px 20px 10px 0;
    padding: 5px 10px 0 49px;
    border-radius: 5px;
    border: 1px solid #a8a8a8;
    box-shadow: 2px 3px 6px 0 #30465147;
    line-height: 29px;
    font-size: 16px;
    color: #333;
    width: 400px;
    height: 80px;
    display: inline-block;
    text-overflow: ellipsis;
    border-bottom-right-radius: 60px 5px;
}
.sticky__item::before {
    content: '';
    background: #88959a;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 40px;
}
.sticky__item:hover {
    box-shadow: 2px 3px 6px 0 #607D8B;
}
.sticky__item__clipper {
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.sticky__item__content {
    display: table;
    width: 100%;
    height: 100%;
}
.sticky__item__content > p{
    display: table-cell;
    vertical-align: middle;
}
.sticky__item__content > p .icon-link {
    text-decoration: none;
    font-size: 18px;
}
.sticky__item__content > textarea {
    outline: none;
    border: none;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 0px;
    resize: none;
    background: transparent;
}
.sticky__3_lines .sticky__item__content,
.sticky__4_lines .sticky__item__content,
.sticky__5_lines .sticky__item__content,
.sticky__6_lines .sticky__item__content {
    font-size: 13px;
    line-height: 18px;
}
.sticky__item__number {
    position: absolute;
    left: auto;
    right: 5px;
    bottom: 25px;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    font-size: 18px;
    font-weight: 700;
    background: #fbfbfb;
    padding-top: 2px;
    color: #828282;
}
.sticky__form {
    display: block;
    padding: 7px;
}
.sticky__form textarea {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    border: 1px solid #d5d5d5;
    box-shadow: 2px 1px 8px 0px #dfdfdf;
    padding: 10px;
}
.sticky__form textarea.focused {
    min-height: 100px;
}
.sticky__form input {
    border: 0;
    outline: 0;
    margin-right: 10px;
    padding: 0 5px;
    border-bottom: 1px solid #dfe1e6;
    height: 25px;
}
.sticky__form button {

}
.sticky__editing {
    position: absolute;
    top: -12px;
    right: 165px;
    font-size: 20px;
    color: #9e9e9e;
    display: none;
}
.sticky__item.config .sticky__editing,
.sticky__item.editing .sticky__editing {
    cursor: pointer;
    display: block;
}
.sticky__editing span {
    margin-left: 5px;
}
.sticky__editing__save {
    cursor: pointer;
    display: none;
}
.sticky__item.editing .sticky__editing__edit {
    display: none;
}
.sticky__item.editing .sticky__editing__save {
    display: inline-block;
}
.sticky__item__note {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #000000a6;
    z-index: 99;
}
.sticky__item__note .inner {
    width: 90%;
    height: 90%;
    margin: auto;
    margin-top: 30px;
    position: relative;
}
.sticky__item__note .inner .vue-codemirror,
.sticky__item__note .inner .CodeMirror {
    height: 100%;
}
.sticky__item__note .inner .CodeMirror {
    padding: 5px;
}
.sticky__item__note_toggler {
    position: absolute;
    cursor: pointer;
    left: 9px;
    top: 25px;
    z-index: 2;
}
.sticky__item.config .sticky__item__note_toggler {
    display: none;
}
.sticky__item__note .inner .btn-save {
    position: absolute;
    padding: 10px;
    top: 0;
    right: 6px;
    cursor: pointer;
    z-index: 2;
    color: #666;
}
.sticky__item__note .inner .btn-save:hover {
    color: #333;
}
.sticky__tools {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    display: none;
}
.sticky__item.config .sticky__tools {
    display: flex;
    flex-direction: column;
}
.sticky__tool {
    color: #dcdcdc;
    font-size: 18px;
    cursor: pointer;
    flex: 1;
    text-align: center;
}
.sticky__tool:hover {
    color: #fff;
}
.sticky__tool__delete {
    font-size: 18px;
}
.sticky__tool__up {
    font-size: 18px;
}
.sticky__tool__down {
    font-size: 18px;
}
.sticky__colors {
    position: absolute;
    top: -11px;
    right: 8px;
    display: none;
    z-index: 9;
}
.sticky__item.config .sticky__colors {
    display: block;
}
.sticky__colors .sticky__color {
    border-radius: 20px;
    cursor: pointer;
}
.sticky__colors .sticky__color:hover {
    box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.1);
}
.sticky__date {
    position: absolute;
    bottom: 0;
    right: 7px;
    font-size: 10px;
    color: #b0b0b0;
    display: none;
}
.sticky__item.config .sticky__date {
    display: block;
}
.sticky__color_4 .sticky__date {
    color: #8a8a8a;
}
.sticky__groups {
    position: absolute;
    top: -12px;
    right: 120px;
    font-size: 24px;
    color: #9E9E9E;
    cursor: pointer;
    display: none;
}
.sticky__item.config .sticky__groups {
    display: block;
}
.sticky__groups .dropdown-menu {
    border-radius: 2px;
    box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    max-height: 90vh;
    overflow: auto;
    margin: -10px 0 0;
}
.sticky__groups:hover .dropdown-menu {
    display: block;
}
.sticky__groups .dropdown-menu .h {
    padding: 0 0 0 10px;
    font-size: 16px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 8px;
}
.sticky__groups .dropdown-menu .dropdown-item {
    text-transform: uppercase;
}
.sticky__error {
    background: pink;
    padding: 5px;
    border: 1px solid #d5d5d5;
    box-shadow: 2px 1px 8px 0px #dfdfdf;
    margin-bottom: 10px;
}
.sticky__error a {
    font-weight: bold;
    color: #000;
    text-decoration: underline;
}
.sticky__color {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 5px;
    border: 1px solid gray;
}
.sticky__color_1 {
    background: #fff;
}
.sticky__color_2 {
    background: #fff6a7;
}
.sticky__color_3 {
    background: #cbf5cb;
}
.sticky__color_4 {
    background: pink;
}
.sticky__color.active {
    border: 3px groove #d2d2d2;
    width: 23px;
    height: 23px;
    box-shadow: 3px 3px 3px black;
}
.sticky__handle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 40px;
}
.sticky__item.config .sticky__handle {
    display: none;
}
.sticky__btn__new {
    display: inline-block;
    padding: 4px 10px 4px 10px;
    font-size: 20px;
}
.sticky__btn__new:hover {
    opacity: .8;
}
.sticky__empty {
    padding: 10px 0;
    font-style: italic;
}
@media (max-width: 777px) {
    .sticky__form {
        padding: 0;
    }
    .ui-tabs.sticky__tabs .ui-tabs-nav {
        margin: 0;
    }
    .sticky__form input {
        width: 100px;
    }
    .sticky__items {
        padding: 0;
    }
    .sticky__item {
        max-width: 100%;
        margin: 8px 0px 0px 0;
    }
    .sticky__item__note .inner {
        width: 100%;
        height: 100%;
    }
}
</style>
