<template>
    <div :class="getItemClass(item)" :id="'item_' + item.id" :data-id="item.id">
        <div class="sticky__item__clipper" @click="toggleConfig(item, $event)" @dblclick.stop="toggleEditing(item, $event)">
            <div class="sticky__item__content">
                <p v-html="parseMarkdown(item.content)" v-if="!isEditing"></p>
                <textarea v-model="editingContent" v-if="isEditing"></textarea>
            </div>
        </div>
        <div class="sticky__item__number" v-if="item.number">{{ item.number }}</div>
        <div class="sticky__item__note" v-if="isEditingNote">
            <div class="inner">
                <codemirror v-model="editingNote" :options="codeMirrorOptions"></codemirror>
                <span class="btn-save icon-checkmark" @click.stop="updateItemNote(item, editingNote, $event)"></span>
            </div>
        </div>
        <div class="sticky__item__note_toggler" @click.stop="toggleEditingNote(item, $event)" v-if="item.note">
            <svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"><g transform="translate(0 -1028.4)"><path d="m2 4v13.531 2.469c0 1.105 0.8954 2 2 2h4 8l6-6v-12h-20z" fill="#f1c40f" transform="translate(0 1028.4)"/><g fill="#f39c12"><path d="m5 9v1h14v-1h-14zm0 3v1h14v-1h-14zm0 3v1h7v-1h-7z" transform="translate(0 1028.4)"/><path d="m22 1044.4-6 6v-4c0-1.1 0.895-2 2-2h4z"/><path d="m2 1033.4v-1c0-1.1 0.8954-2 2-2h4 8 4c1.105 0 2 0.9 2 2v1h-20z"/></g></g></svg>
        </div>
        <div class="sticky__tools">
            <span class="sticky__tool sticky__tool__up" @click="up(item, $event)"><span class="icon-arrow_drop_up"></span></span>
            <span class="sticky__tool sticky__tool__delete" data-warning="Do you want to delete this item?" @click="remove(group, index, $event)"><span class="icon-bin"></span></span>
            <span class="sticky__tool sticky__tool__down" @click="down(item, $event)"><span class="icon-arrow_drop_down"></span></span>
        </div>
        <div class="sticky__editing">
            <span class="sticky__editing__note" style="margin-right: 18px" @click.stop="toggleEditingNote(item, $event)">
                <svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"><g transform="translate(0 -1028.4)"><path d="m2 4v13.531 2.469c0 1.105 0.8954 2 2 2h4 8l6-6v-12h-20z" fill="#f1c40f" transform="translate(0 1028.4)"></path><g fill="#f39c12"><path d="m5 9v1h14v-1h-14zm0 3v1h14v-1h-14zm0 3v1h7v-1h-7z" transform="translate(0 1028.4)"></path><path d="m22 1044.4-6 6v-4c0-1.1 0.895-2 2-2h4z"></path><path d="m2 1033.4v-1c0-1.1 0.8954-2 2-2h4 8 4c1.105 0 2 0.9 2 2v1h-20z"></path></g></g></svg>
            </span>
            <span class="sticky__editing__edit icon-pencil" @click.stop="toggleEditing(item, $event)"></span>
            <span class="sticky__editing__save icon-checkmark" @click.stop="updateContent(item, editingContent, $event)"></span>
        </div>
        <div class="sticky__colors">
            <span v-for="color in colors" :class="[{ sticky__color: true }, 'sticky__color_' + color]" @click="updateItemColor(item, color, $event)"></span>
        </div>
        <div class="sticky__date">{{ item.friendly_date }}</div>
        <div class="sticky__groups" v-show="groups.length > 1">
            <span class="icon-label" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></span>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div class="h">Move to:</div>
                <input type="text" class="dropdown-item" placeholder="Search..." @keyup.stop="onDropdownFilter"/>
                <a v-for="targetGroup in groups" class="dropdown-item" href="#" v-show="targetGroup != group" @click="moveTo(group, targetGroup, index, $event)">{{ targetGroup }}</a>
            </div>
        </div>
        <div class="sticky__handle"></div
    </div>
</template>

<script>
import marked from 'marked';
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/markdown/markdown.js'
import 'codemirror/addon/display/placeholder.js'

export default {
    name: 'StickyNoteItem',
    props: {
        item: { type: Object, required: true },
        group: { type: String, required: true },
        index: { type: Number, required: true },
        groups: { type: Array, required: true },
        colors: { type: Array, required: true },
        updateContent: { type: Function, required: true },
        updateItemColor: { type: Function, required: true },
        updateItemNote: { type: Function, required: true },
        up: { type: Function, required: true },
        remove: { type: Function, required: true },
        down: { type: Function, required: true },
        toggleConfig: { type: Function, required: true },
        isConfig: { type: Boolean, required: true },
        toggleEditing: { type: Function, required: true },
        toggleEditingNote: { type: Function, required: true },
        isEditing: { type: Boolean, required: true },
        isEditingNote: { type: Boolean, required: true },
        moveTo: { type: Function, required: true }
    },
    components: {
        codemirror
    },
    data: function () {
        return {
            editingContent: '',
            editingNote: ''
        }
    },
    computed: {
        codeMirrorOptions: function() {
            return {
                mode: "markdown",
                theme: "none",
                lineWrapping: true,
                matchBrackets: true
            }
        }
    },
    mounted: function () {
        this.editingContent = this.item.content
        this.editingNote = this.item.note
    },
    methods: {
        parseMarkdown: function(content) {
            return marked(content)
        },
        getItemClass: function (item) {
            let cls = ['sticky__item', 'sticky__color_' + item.color]

            if (this.isEditing) {
                cls.push('editing')
            }

            if (this.isConfig && !this.isEditing) {
                cls.push('config')
            }

            let div = document.createElement("div")
            div.innerHTML = item.content
            let text = div.textContent || div.innerText || ""
            let nbr_lines = Math.ceil(text.length / 35)

            if (nbr_lines > 1) {
                cls.push('sticky__' + nbr_lines + '_lines')
            }

            return cls.join(' ')
        },
        onDropdownFilter: function(event) {
            let input = $(event.target)
            let search = input.val().toLowerCase();

            input.closest('.dropdown-menu').find('a.dropdown-item').filter(function() {
                $(this).toggle($(this).text().toLowerCase().indexOf(search) > -1)
            })
        }
    }
}
</script>
